<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="this.type == 'edit' ? $t('cip.plat.sys.dataLink.title.editHeadTitle') : $t('cip.plat.sys.dataLink.title.addHeadTitle')"
      @head-save="headSave()"
      @head-save-cancel="headSave(true)"
      @head-cancel="headCancel"
      @head-test="testConn"
    ></head-layout>
    <el-scrollbar>
      <form-layout
        ref="formLayout"
        :column="formColumn"
        :dataForm="dataForm"
      ></form-layout>
    </el-scrollbar>
  </div>
</template>
<script>

import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {update, test, getDetail} from "@/api/system/dataLink";


export default {
  name: "dataLinkEdit",
  components: {
    FormLayout,
    HeadLayout
  },
  data() {
    return {
      type: 'view',
      dataForm: {},
    }
  },
  computed: {
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.type)) {
        result.push(
          {
            label: this.$t('cip.cmn.btn.saveBtn'),
            emit: "head-save",
            type: "button",
            icon: "",
            btnOptType: 'save',
          }
        );
        result.push(
          {
            // todo 保存并返回
            label: this.$t('cip.cmn.btn.saveBackBtn'),
            emit: "head-save-cancel",
            type: "button",
            icon: "",
          }
        );
      }
      result.push(
        {
          label: this.$t('cip.plat.sys.dataLink.btn.testBtn'),
          emit: "head-test",
          type: "button",
          icon: "",
        }
      );
      result.push(
        {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: 'cancel',
        }
      );
      return result;
    },
    formColumn() {
      return [
        {
          label: this.$t('cip.plat.sys.dataLink.field.linkName'),
          prop: 'linkName',
          labelWidth: 130,
          span: 8,
          maxlength: 50,
          showWordLimit: true,
          placeholder: this.$t(`cip.plat.sys.dataLink.field.linkName`),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t(`cip.plat.sys.dataLink.field.linkName`),
              trigger: "blur"
            }
          ],
        },
        {
          label: this.$t('cip.plat.sys.dataLink.field.driver'),
          prop: 'driver',
          type: 'select',
          span: 8,
          dataType: "string",
          placeholder: this.$t(`cip.plat.sys.dataLink.field.driver`),
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=DB_DRIVER",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.sys.dataLink.field.driver'),
              trigger: "blur"
            },
          ],
        },
        {
          label: this.$t('cip.plat.sys.dataLink.field.host'),
          prop: 'host',
          labelWidth: 130,
          span: 8,
          maxlength: 100,
          showWordLimit: true,
          readonly: !['add', 'edit'].includes(this.type),
          placeholder: this.$t(`cip.plat.sys.dataLink.field.host`),
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.sys.dataLink.field.host'),
            trigger: "blur"
          }],
        },
        {
          label: this.$t('cip.plat.sys.dataLink.field.port'),
          prop: 'port',
          labelWidth: 130,
          dataType: 'string',
          display: true,
          maxlength: 50,
          showWordLimit: true,
          readonly: !['edit', 'add'].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.sys.dataLink.field.port'),
              trigger: "blur"
            }
          ],
        },
        {
          label: this.$t('cip.plat.sys.dataLink.field.user'),
          prop: 'user',
          maxlength: 100,
          showWordLimit: true,
          span: 8,
          readonly: !['add', 'edit'].includes(this.type),
          placeholder: this.$t(`cip.plat.sys.dataLink.field.user`),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.sys.dataLink.field.user'),
              trigger: "blur"
            }
          ],
        },
        {
          label: this.$t('cip.plat.sys.dataLink.field.password'),
          prop: 'password',
          labelWidth: 130,
          maxlength: 100,
          span: 8,
          showWordLimit: true,
          readonly: !['add', 'edit'].includes(this.type),
          placeholder: this.$t(`cip.plat.sys.dataLink.field.password`),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.sys.dataLink.field.password'),
              trigger: "blur"
            }
          ],
        },
        {
          label: this.$t('cip.plat.sys.dataLink.field.dbName'),
          prop: 'dbName',
          labelWidth: 130,
          maxlength: 100,
          span: 8,
          showWordLimit: true,
          readonly: !['add', 'edit'].includes(this.type),
          placeholder: this.$t(`cip.plat.sys.dataLink.field.dbName`),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.sys.dataLink.field.dbName'),
              trigger: "blur"
            }
          ],
        },
        {
          label: this.$t('cip.plat.sys.dataLink.field.sort'),
          prop: 'sort',
          type: 'number',
          span: 8,
          minRows: 0,
          maxRows: 100000,
          readonly: !['add', 'edit'].includes(this.type),
          placeholder: this.$t(`cip.plat.sys.dataLink.field.sort`),
        },
        {
          label: this.$t('cip.plat.sys.dataLink.field.remark'),
          labelWidth: 130,
          maxlength: 200,
          span: 16,
          prop: 'remark',
          type: 'textarea',
          showWordLimit: true,
          readonly: !['add', 'edit'].includes(this.type),
          placeholder: this.$t(`cip.plat.sys.dataLink.field.remark`),
        },
      ]
    },
  },
  created() {
    let {id, type} = this.$route.query;
    this.type = type;
    if (['view', 'edit'].includes(this.type)) {
      this.dataForm.id = id
      this.initData()
    }
  },
  mounted() {
  },
  methods: {
    testConn() {
      this.$refs.formLayout.$refs.form.validate(async (valid) => {
        if (valid) {
          this.$loading()
          test({
            ...this.$refs.formLayout.dataForm,
          })
            .then(res => {
              console.log(res)
              if (res.data.code == '200') {
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              }
            }).finally(() => {
            this.$loading().close()
            this.$refs.formLayout.$refs.form.allDisabled = false;
          })
        }
      })
    },
    initData() {
      getDetail(this.dataForm.id).then(res => {
        const {data} = res.data;
        this.dataForm = data;
      })
    },
    headSave(cancel = false) {
      this.$refs.formLayout.dataForm.linkName = this.$refs.formLayout.dataForm.linkName.trim()
      this.$refs.formLayout.dataForm.host = this.$refs.formLayout.dataForm.host.trim()
      this.$refs.formLayout.dataForm.port = this.$refs.formLayout.dataForm.port.trim()
      this.$refs.formLayout.dataForm.user = this.$refs.formLayout.dataForm.user.trim()
      this.$refs.formLayout.dataForm.password = this.$refs.formLayout.dataForm.password.trim()
      this.$refs.formLayout.dataForm.dbName = this.$refs.formLayout.dataForm.dbName.trim()
      this.$refs.formLayout.$refs.form.validate(async (valid) => {
        if (valid) {
          this.$loading()
          update({
            ...this.$refs.formLayout.dataForm,
          })
            .then(res => {
              const {msg, data} = res.data;
              if (res.data.code == 200) {
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              } else {
                this.$message.success(msg);
              }
              if (cancel) {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              } else {
                //刷新数据
                Object.assign(this.dataForm, data)
                this.type = 'edit';
              }
            })
            .finally(() => {
              this.$loading().close()
              this.$refs.formLayout.$refs.form.allDisabled = false;
            })
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  }
}
</script>

<style scoped>

</style>
